import {mapActions, mapGetters, mapMutations} from "vuex";
import category from "@/components/header/components/category/index.vue";
import burgerMenu from "@/components/header/components/burger-menu/index.vue";
import search from "@/components/header/components/search/index.vue";
import {directive as onClickaway} from 'vue-clickaway';
import changeHtmlLang from "@/utils/change-html-lang";
import { debounce } from 'lodash'

export default {
  name: "main-header",
  components: {
    category,
    burgerMenu,
    search
  },
  directives: {
    onClickaway: onClickaway,
  },
  data(){
    return{
      isDesktop: true,
      burger: false,
      showLangList: false,
      showCurrencyList: false,
      toggleSearch: false,
      searchData: '',
    }
  },
  mounted() {
    window.innerWidth <= 1180 ? this.isDesktop = false : this.isDesktop = true;
  },
  watch: {
    '$route'(newVal){
      if(newVal){
        this.burger = false
        this.toggleSearch = false
      }
    },
    burger: {
      immediate: true,
      handler(newVal) {
        if(newVal === true){
          this.fixBody(true)
        }
        else {
          this.fixBody(false)
        }
      }
    },
    toggleSearch: {
      immediate: true,
      handler(newVal) {
        if(newVal === true){
          this.fixBody(true)
        }
        else if(newVal === false && this.burger === true){
          this.fixBody(true)
        }
        else{
          this.fixBody(false)
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'currentLanguage',
    ]),
    ...mapGetters({
      basket: 'basket/basket',
      locationCountry: 'setting/locationCountry',
      categoriesList: 'category/categories',
      catalogCategoriesList: 'category/catalogCategories',
      isAuthenticated: 'auth/isAuthenticated',
      searchList: 'home/search',
      currencyList: 'setting/currencyList',
      currency: 'setting/currency',
      mainCategories: 'category/mainCategories',
      searchLoading: 'home/searchLoading'
    }),

  },
  created() {
    this.getMain()
  },
  methods: {
    ...mapMutations({
      changeNewCurrency: 'setting/CHANGE_CURRENCY',
      fixBody: 'system/FIX_BODY'
    }),
    ...mapActions({
      getSearch: 'home/GET_SEARCH',
      getMain: 'category/GET_MAIN_CATEGORIES'
    }),
    closeLangList(){
      this.showLangList = false;
    },
    closeCurrencyList(){
      this.showCurrencyList = false;
    },
    changeLanguage(language){
      const selectedLanguage = this.languages.find(item => item.key === language.key);
      let route = this.$route.path
      if (route.slice(1, 4) === 'ru/' || route.slice(1, 4) === 'en/') {
        route = route.slice(3)
      }
      let url = ''
      selectedLanguage.translationKey === 'en' ? url = window.location.origin + route : url =  window.location.origin + `/${selectedLanguage.translationKey}` + route
      console.log(selectedLanguage, url);
      this.$setLanguage(selectedLanguage.code);
      localStorage.setItem('localization', selectedLanguage.key);
      changeHtmlLang(selectedLanguage.key);
      this.showLangList = false;
      setTimeout(() => {
        window.location.href = url;
      }, 200)
    },
    search: debounce(function (data) {
      this.getSearch(data)
    }, 500),
    changeCurrency(item){
      this.changeNewCurrency(item)
      setTimeout(()=>{
        location.reload()
      },300)
    },
    alternateLink(language){
      const selectedLanguage = this.languages.find(item => item.key === language.key);
      let route = this.$route.path
      if (route.slice(1, 4) === 'ru/' || route.slice(1, 4) === 'en/') {
        route = route.slice(3)
      }
      let url = ''
      selectedLanguage.translationKey === 'en' ? url = window.location.origin + route : url =  window.location.origin + `/${selectedLanguage.translationKey}` + route
      return url
    }
  }
}